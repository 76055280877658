import { useContext, useEffect, useState } from "react";
import CategoryContext from "../store/category-context";
import SavedContext from "../store/saved-context";
import { useQuery, gql } from "@apollo/client";

const useGetItems = (category = "") => {
  let GET_ITEMS = gql`
    query ItemsQuery($category: String!) {
      products(
        where: { category: $category, status: "published", visibility: VISIBLE }
        first: 25
      ) {
        nodes {
          id
          slug
          name
          description(format: RAW)
          shortDescription(format: RENDERED)
          image {
            sourceUrl(size: LARGE)
          }
          ... on VariableProduct {
            galleryImages(first: 200) {
              nodes {
                sourceUrl
              }
            }
            id
            name
            productCategories(first: 5) {
              nodes {
                id
                name
                slug
              }
            }
            attributes(first: 10) {
              nodes {
                ... on GlobalProductAttribute {
                  id
                  terms(first: 100) {
                    nodes {
                      name
                      id
                    }
                  }
                  label
                }
                name
                options
                label
              }
            }
          }
        }
      }
    }
  `;

  const { data, loading, error } = useQuery(GET_ITEMS, {
    variables: { category },
  });

  return [data ? data.products.nodes : data, loading, error];
};

/*const useGetItems = (storageValue = undefined) => {
  const [data, setData] = useState(
    JSON.parse(localStorage.getItem(storageValue)) || []
  );

  const catCtx = useContext(CategoryContext);
  const saveCtx = useContext(SavedContext);

  const getData = async (path) => {
    try {
      if (catCtx.categories && catCtx.categories.length > 0) {
        let response = await fetch(
          `https://mediteraneo.eu/wp-json/wc/v3/products${path}&status=publish`,
          { mode: "cors" }
        );

        let value = await response.json();

        console.log(await value);

        console.log(await value);
        if (value.length > 0) {
          value = saveCtx.checkIfSaved(await value);
        }

        value = await value.sort((a, b) => a.price - b.price);

        setData(await value);

        if (storageValue !== undefined) {
          localStorage.setItem(storageValue, JSON.stringify(await value));
        }

        return await value;
      }
    } catch (error) {
      console.log("Fetch error: ", error.message);
    }
  };

  return [data, setData, getData];
};
*/

export default useGetItems;
