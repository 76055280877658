import { Fragment, useEffect, useState } from "react";
import styles from "./SizeItem.module.css";
import QuantitySelector from "./QuantitySelector";

const SizeItem = (props) => {
  const size = props.size;
  const price = props.price;
  const index = props.index;
  const color = props.color;

  const [quantity, setQuantity] = useState(0);
  const setVariation = (value) => {
    let temp = props.forBasket.filter(
      (el) => el.id !== props.variations[index].id
    );

    if (value !== 0)
      temp = [
        ...temp,
        {
          ...props.variations[index],
          quantity: value,
          attributes: { color, size },
        },
      ];

    setQuantity(value);
    props.setForBasket(temp);
  };

  return (
    <Fragment>
      <div className={styles.wrapper}>
        <div className={styles.info}>
          <div className={styles.size}>{size}</div>
          {
            <QuantitySelector
              index={index}
              setQuantity={setVariation}
              quantity={quantity}
            />
          }
        </div>
        <div className={styles.price}>
          {props.variations[index].price || 0} EUR
        </div>
      </div>
      <div className={styles.divider} />
    </Fragment>
  );
};

export default SizeItem;
