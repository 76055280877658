import styles from "./SpecialSection.module.css";
import SpecialSectionOptions from "./SpecialSectionOptions";
import SpecialSectionRepresentation from "./SpecialSectionRepresentation";
import ShowItem from "../../../UI/ShowItem";
import { useEffect, useState, useContext, Fragment } from "react";
import useGetItems from "../../../hooks/use-get-items";
import CategoryContext from "../../../store/category-context";
// temp IMG
import tempImage1 from "../../../../assets/graciozaKolekcija1.jpg";
import tempImage2 from "../../../../assets/graciozaKolekcija2.jpg";
import tempImage3 from "../../../../assets/graciozaKolekcija3.jpg";
import tempImage4 from "../../../../assets/graciozaKolekcija4.jpg";

const options = [
  {
    name: "Inspiracije",
    slug: "inspiracije",
    image: tempImage1,
    item: undefined,
  },
  {
    name: "Special prices",
    slug: "special_prices",
    image: tempImage2,
    item: undefined,
  },
  {
    name: "Zanimljivosti",
    slug: "zanimljivosti",
    image: tempImage3,
    item: undefined,
  },
  {
    name: "Novo u ponudi",
    slug: "novo_u_ponudi",
    image: tempImage4,
    item: undefined,
  },
];

const SpecialSection = () => {
  const [selectionIndex, setSelectionIndex] = useState(0);
  const { selectedSectionData } = useContext(CategoryContext);

  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (selectedSectionData && selectedSectionData.length >= 4) {
      for (let i = 0; i < 4; i++) {
        options[i].item = selectedSectionData[i];
      }
    }
  }, [selectedSectionData]);

  useEffect(() => {
    setIsChanged(true);
    setTimeout(() => {
      setIsChanged(false);
    }, [1]);
  }, [selectionIndex]);

  return (
    <div className={styles.wrapper}>
      <SpecialSectionOptions
        selectionIndex={selectionIndex}
        setSelectionIndex={setSelectionIndex}
        options={options}
      />

      <div className={styles["content-wrapper"]}>
        {!isChanged && (
          <Fragment>
            <SpecialSectionRepresentation
              image={options[selectionIndex].image}
              name={options[selectionIndex].name}
            />
            <div className={styles["show-item"]}>
              {options &&
                options[selectionIndex] &&
                options[selectionIndex].item && (
                  <ShowItem
                    item={options[selectionIndex].item}
                    withDescription={true}
                  />
                )}
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default SpecialSection;
