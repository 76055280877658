import styles from "./ColorSelection.module.css";
import { Fragment, useState } from "react";
import SelectionLabel from "../../UI/SelectionLabel";
import RadioButton from "../../UI/RadioButton";

export default function ColorSelection({
  modifiedColors,
  selectedColorIndex,
  setColorIndex,
}) {
  const [hoverIndex, setHoverIndex] = useState(-1);

  const changeColorHandler = (e) => {
    setColorIndex(parseInt(e.currentTarget.getAttribute("index")));
  };

  const mouseHoverLeave = () => {
    setHoverIndex(-1);
  };

  return (
    <Fragment>
      <SelectionLabel
        text="Odaberi boju: "
        additionalText={
          modifiedColors.colorNames &&
          modifiedColors.colorNames[selectedColorIndex]
        }
        hoverIndex={
          (modifiedColors.colorNames &&
            modifiedColors.colorNames[hoverIndex]) ||
          -1
        }
      />
      <div className={styles[`color-list`]} onMouseLeave={mouseHoverLeave}>
        {modifiedColors.colorHashes &&
          modifiedColors.colorHashes.map((color, i) => {
            return (
              <RadioButton
                active={i === selectedColorIndex ? true : false}
                setHoverIndex={setHoverIndex}
                key={i}
                color={color}
                index={i}
                onClick={changeColorHandler}
              />
            );
          })}
      </div>
    </Fragment>
  );
}
