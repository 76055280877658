import { Fragment, useEffect, useState } from "react";
import styles from "./ItemSelection.module.css";
import RadioButton from "../../UI/RadioButton";

import SelectionLabel from "../../UI/SelectionLabel";
import SizeItem from "../../UI/SizeItem";
import LoadingAnimation from "../../UI/LoadingAnimation";
import useColorManagment from "../../hooks/use-color-managment";
import useGetItemVariations from "../../hooks/use-get-item-variations";
import ColorSelection from "./ColorSelection";

const ItemSelection = (props) => {
  const colors = props.color;
  const sizes = props.size;
  const [variations, variationsLoading, variationsError] = useGetItemVariations(
    props.itemSlug
  );

  const modifiedColors = useColorManagment(colors);

  return (
    <Fragment>
      <div className={styles[`wrapper`]}>
        <div className={styles[`selectors`]}>
          <ColorSelection
            modifiedColors={modifiedColors}
            selectedColorIndex={props.selectedColorIndex}
            setColorIndex={props.setColorIndex}
          />

          <div className={styles[`size-selector`]}>
            <SelectionLabel text="Odaberi veličine" />
            {!variationsLoading &&
            variations !== undefined &&
            modifiedColors.colorSlugs ? (
              <div className={styles[`size-list`]}>
                {variations.toReversed().map((vari, i) => {
                  if (
                    modifiedColors.colorSlugs[props.colorIndex] ===
                    vari.attributes.nodes.filter(
                      (cl) => cl.name === "pa_color"
                    )[0].value
                  ) {
                    return (
                      <SizeItem
                        key={i}
                        size={
                          sizes.terms.nodes[
                            sizes.options.findIndex(
                              (sz) =>
                                sz ===
                                vari.attributes.nodes.filter(
                                  (el) => el.name === "pa_size"
                                )[0].value
                            )
                          ].name
                        }
                        price={0}
                        index={i}
                        forBasket={props.forBasket}
                        setForBasket={props.setForBasket}
                        variations={variations}
                        color={{
                          slug: modifiedColors.colorSlugs[props.colorIndex],
                          name: modifiedColors.colorNames[props.colorIndex],
                          hash: modifiedColors.colorHashes[props.colorIndex],
                        }}
                      />
                    );
                  }
                })}
              </div>
            ) : (
              <div className={styles["loading-wrapper"]}>
                <LoadingAnimation />
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ItemSelection;
