import { useEffect, useState } from "react";
import styles from "./CartItem.module.css";
import { Fragment } from "react";

//temp
import tempImg from "../../assets/coffe maker.png";
import exitIcon from "../../assets/navigation/menu-x.png";
import { scryRenderedComponentsWithType } from "react-dom/test-utils";
import useColorManagment from "../hooks/use-color-managment";

const CartItem = (props) => {
  const item = props.item;
  const [totalPrice, setTotalPrice] = useState(22.21);
  const [quantity, setQuantity] = useState(item.quantity);

  const onIncreaseHandler = (value) => {
    for (let i = 0; i < value; i++) {
      props.addItem([item]);
    }
  };

  const onDecreaseHandler = (value) => {
    for (let i = 0; i < value; i++) {
      props.removeItem(item.id, false);
    }
  };

  const onRemoveHandler = () => {
    props.removeItem(item.id, true);
  };

  const quantityChangeHandler = (e) => {
    const value = e.target.value;

    if (value !== "") {
      if (value > item.quantity) {
        onIncreaseHandler(value - item.quantity);
      } else if (value < item.quantity) {
        onDecreaseHandler(item.quantity - value);
      }
    }
  };

  const onFocusOut = (e) => {
    const value = e.target.value;

    if (value === "") {
      if (1 < item.quantity) {
        onDecreaseHandler(item.quantity - 1);
      }

      setQuantity(1);
    }
  };

  return (
    <Fragment>
      <div className={styles.item} key={item.id}>
        <img
          onClick={onRemoveHandler}
          className={styles[`close-button`]}
          src={exitIcon}
        />

        <img className={styles.image} src={item.image.sourceUrl} />
        <div className={styles[`info`]}>
          <div>
            <div className={styles.name}>{item.name}</div>
            <div className={styles.options}>
              <div>Boja: {item.attributes.color.name}</div>
              <div>Veličina: {item.attributes.size}</div>
            </div>
          </div>

          <div className={styles[`total-wrapper`]}>
            <div className={styles[`price-wrapper`]}>
              <div>{item.price || "0.00"} €</div>
            </div>
            <input
              className={styles[`quantity-wrapper`]}
              type="number"
              id="quantity"
              name="quantity"
              value={quantity}
              onChange={quantityChangeHandler}
              onBlur={onFocusOut}
            />
            <div className={styles[`price-wrapper`]}>
              <div>{(item.price * item.quantity).toFixed(2)} €</div>
            </div>
          </div>
        </div>
      </div>
      <hr className={styles[`item-divider`]} />
    </Fragment>
  );
};

export default CartItem;
